export type EventType =
	| 'exploring_course_content'
	| 'added_to_cart'
	| 'viewed_cart'
	| 'initiated_checkout'
	| 'login_or_registration_required'
	| 'authentication_completed'
	| 'checkout_in_process'
	| 'purchase_made'
	| 'contact_us_submitted';

export interface BaseEventProperties {
	cart_course_count?: number;
	cart_total_value?: number | null;
	component?: string;
}

export interface CourseEventProperties extends BaseEventProperties {
	course_name: string;
	course_discount_value_eur: number | null;
}

export interface PurchaseEventProperties extends BaseEventProperties {
	payer_type: 'personal' | 'company' | 'two_payers' | 'tootukassa' | undefined;
	payment_type: 'bank' | 'invoice';
	coupon_code: string | null;
}

interface TypedEventConfig<T extends EventType, P> {
	type: T;
	properties: P;
}

type EventWithComponent<P, C extends string> = P & { component: C };

const createEventConfig = <T extends EventType, P>(type: T, properties: P) =>
	({
		type,
		properties,
	}) as TypedEventConfig<T, P>;

export const EventConfig = {
	course_tile_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
	} as CourseEventProperties),

	course_video_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'mobile_side_card' | 'desktop_side_card',
	} as EventWithComponent<CourseEventProperties, 'mobile_side_card' | 'desktop_side_card'>),

	course_version_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
	} as CourseEventProperties),

	overview_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	topics_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	calendar_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	prerequisites_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	instructors_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	reviews_section_clicked: createEventConfig('exploring_course_content', {
		course_name: '',
		component: '' as 'course_tabs' | 'course_footer',
	} as EventWithComponent<CourseEventProperties, 'course_tabs' | 'course_footer'>),

	// Events related to cart actions
	add_to_cart_clicked: createEventConfig('added_to_cart', {
		course_name: '',
		component: '' as
			| 'side_card'
			| 'side_card_mobile'
			| 'contact_us_section'
			| 'contact_us_form',
	} as EventWithComponent<
		CourseEventProperties,
		'side_card' | 'side_card_mobile' | 'contact_us_section' | 'contact_us_form'
	>),

	go_to_cart_clicked: createEventConfig('viewed_cart', {
		component: '' as 'toast' | 'contact_us_section' | 'drawer',
	} as EventWithComponent<BaseEventProperties, 'toast' | 'contact_us_section' | 'drawer'>),

	cart_drawer_opened: createEventConfig('viewed_cart', {
		component: '' as 'cart_button_mobile' | 'cart_button_desktop',
	} as EventWithComponent<BaseEventProperties, 'cart_button_mobile' | 'cart_button_desktop'>),

	checkout_clicked: createEventConfig('initiated_checkout', {} as BaseEventProperties),

	login_or_registration_required: createEventConfig(
		'login_or_registration_required',
		{} as BaseEventProperties,
	),

	authentication_completed: createEventConfig(
		'authentication_completed',
		{} as BaseEventProperties,
	),

	participants_details_step_clicked: createEventConfig(
		'checkout_in_process',
		{} as BaseEventProperties,
	),

	participants_checkbox_clicked: createEventConfig(
		'checkout_in_process',
		{} as BaseEventProperties,
	),

	payer_details_step_clicked: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	payment_step_clicked: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	participants_choice_submitted: createEventConfig(
		'checkout_in_process',
		{} as BaseEventProperties,
	),

	payer_type_clicked: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	payment_details_submitted: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	payment_type_clicked: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	payment_terms_clicked: createEventConfig('checkout_in_process', {} as BaseEventProperties),

	pay_button_clicked: createEventConfig('purchase_made', {} as PurchaseEventProperties),

	contact_us_button_clicked: createEventConfig('contact_us_submitted', {
		course_name: '',
	} as CourseEventProperties),
} as const;

export type EventName = keyof typeof EventConfig;
export type EventProperties<T extends EventName> = (typeof EventConfig)[T]['properties'];
