type CollectionWithId = {
	id: string;
};
export function extractId(value: CollectionWithId | string | undefined | null): string {
	if (!value) {
		throw new Error(`Cannot extract id from ${value}`);
	}

	return typeof value === 'string' ? value : value.id;
}
