import { PayloadRequest } from 'payload/types';
import { calculaceVat, calculatePrice } from './price';
import { Courseversion, Course, OrderCartItems, DiscountCode } from '../payload-types';
import { Flatten, extractId } from './typescript';

type OrderCartItem = Flatten<OrderCartItems>;
type GenerateSingleCartOrderItemPriceParams = {
	item: NonNullable<OrderCartItem>;
	// items must contain the courseversions, this is for depth if FE wants to use it?
	courseversion: Courseversion;
	quantity: number;
	// business logic: discount code price reduction can only be done once
	canApplyDiscountCode?: boolean;
	discountCode?: DiscountCode | string | null;
	shouldFlattenOrderedCollection?: boolean;
};
export function generateSingleCartOrderItemPrice({
	item,
	courseversion,
	discountCode,
	canApplyDiscountCode,
	shouldFlattenOrderedCollection,
	quantity,
}: GenerateSingleCartOrderItemPriceParams): NonNullable<OrderCartItem> {
	let discountedPriceWithoutVAT = courseversion.discountedPriceWithoutVAT;
	let discountedVAT = courseversion.discountedVAT;
	let discountedPrice = courseversion.discountedPrice;

	let discountCodeApplied = false;

	if (typeof discountCode === 'string') {
		console.warn(
			`generateSingleCartOrderItemPrice: discountCode is of type string, id: ${discountCode}`,
		);
	}

	if (discountCode && typeof discountCode !== 'string' && canApplyDiscountCode) {
		// apply discountcode discount to price
		// check of price is not already discounted and lower than the code

		// no need to check for discount code active status
		let isDiscountCodeAppliable = false;

		if (discountCode.affiliationType === 'all') {
			isDiscountCodeAppliable = true;
		}

		const discountCourseversionIds = discountCode?.courseversions?.map(extractId) || [];

		if (
			discountCode.affiliationType === 'allWithExclusions' &&
			discountCourseversionIds.length
		) {
			isDiscountCodeAppliable = !discountCourseversionIds.includes(courseversion.id);
		}

		if (discountCode.affiliationType === 'chosen' && discountCourseversionIds.length) {
			isDiscountCodeAppliable = discountCourseversionIds.includes(courseversion.id);
		}

		if (isDiscountCodeAppliable) {
			let discountedCodePriceWithoutVAT = 0;
			let discountedCodeVAT = 0;
			let discountedCodePrice = 0;

			if (discountCode.discountType === 'percentage' && discountCode.percentage) {
				discountedCodePrice = calculatePrice(
					courseversion.price - courseversion.price * (discountCode.percentage / 100),
				);
			} else if (discountCode.discountType === 'flatValue' && discountCode.flatPrice) {
				discountedCodePrice = calculatePrice(courseversion.price - discountCode.flatPrice);
			}

			if (discountedCodePrice) {
				discountedCodeVAT = courseversion.VAT > 0 ? calculaceVat(discountedCodePrice) : 0;
				discountedCodePriceWithoutVAT =
					courseversion.VAT > 0
						? discountedCodePrice - discountedCodeVAT
						: discountedCodePrice;
			}

			const canAddDiscountCode =
				!discountedPrice ||
				Boolean(
					discountedPrice &&
						discountedPrice * quantity >
							courseversion.price * quantity -
								(courseversion.price - discountedCodePrice),
				);

			// only apply if discount code price is lower than discounted courseversion (taking into account quantity), if exists
			// make sure price cannot go under 1 eur just in case..
			if (canAddDiscountCode && discountedCodePrice > 0) {
				discountedPriceWithoutVAT = discountedCodePriceWithoutVAT;
				discountedVAT = discountedCodeVAT;
				discountedPrice = discountedCodePrice;
				discountCodeApplied = true;
			}
		}
	}

	let discountedPriceReduction = 0;

	if (discountedPrice && discountedPrice > 0) {
		if (discountCodeApplied) {
			discountedPriceReduction = calculatePrice(courseversion.price - (discountedPrice || 0));
		} else {
			discountedPriceReduction = calculatePrice(
				(courseversion.price - (discountedPrice || 0)) * quantity,
			);
		}
	}

	return {
		...item,
		orderedCollection: {
			...item.orderedCollection,
			value: shouldFlattenOrderedCollection
				? extractId(item.orderedCollection.value)
				: item.orderedCollection.value,
		},
		priceWithoutVAT: courseversion.enteredPrice,
		VAT: courseversion.VAT,
		price: courseversion.price,
		discountedPriceWithoutVAT,
		discountedVAT,
		discountedPrice,
		discountCodeApplied,
		discountedPriceReduction,
	};
}

type GeneratePricesParams = {
	items?: OrderCartItems;
	discountCode?: DiscountCode | null | string;
	shouldFlattenOrderedCollection?: boolean;
};

export function generateCartOrderItemPrices({
	items,
	discountCode,
	shouldFlattenOrderedCollection,
}: GeneratePricesParams): OrderCartItems {
	if (!items?.length) {
		return [];
	}

	if (typeof discountCode === 'string') {
		console.warn(
			`generateSingleCartOrderItemPrice: discountCode is of type string, id: ${discountCode}`,
		);
	}

	const updatedItems = [...items];

	// business logic: discount code can be applied to whole cart
	let canApplyDiscountCode = true;
	for (const [index, item] of items.entries()) {
		const relatedCourseVersion = item.orderedCollection.value;

		if (typeof relatedCourseVersion === 'string') {
			throw new Error('Bad depth for ordered item courseversion');
		}

		if (!relatedCourseVersion || !relatedCourseVersion.price) {
			throw new Error(`Courseversion with id ${relatedCourseVersion.id} cannot be ordered`);
		}

		const relatedCourse = relatedCourseVersion.course;
		if (typeof relatedCourse === 'string') {
			throw new Error('Depth: cannot get course from courseversion');
		}

		const itemWithPrices = generateSingleCartOrderItemPrice({
			item,
			courseversion: relatedCourseVersion,
			canApplyDiscountCode,
			discountCode: discountCode,
			shouldFlattenOrderedCollection,
			quantity: item.quantity,
		});

		if (canApplyDiscountCode && itemWithPrices.discountCodeApplied) {
			canApplyDiscountCode = false;
		}

		updatedItems[index] = itemWithPrices;
	}

	return updatedItems;
}

export function generateCartOrderTotals({}) {}
