'use client';
import React, { createContext, useContext, ReactNode } from 'react';
import { FullStory, isInitialized } from '@fullstory/browser';
import { EventConfig, EventName, EventProperties, BaseEventProperties } from './EventConfig'; // Adjust import paths as necessary
import { useCart } from '../Cart';
import { Courseversion } from '../../../payload/payload-types';

interface TrackEventContextType {
	trackEvent: <T extends EventName>(eventName: T, properties: EventProperties<T>) => void;
}

const TrackEventContext = createContext<TrackEventContextType | undefined>(undefined);

interface TrackEventProviderProps {
	children: ReactNode;
	user_id: string;
}

export const TrackEventProvider = ({ children, user_id }: TrackEventProviderProps) => {
	const { cart, cartTotal } = useCart();

	const trackEvent = <T extends EventName>(eventName: T, properties: EventProperties<T>) => {
		const eventType = EventConfig[eventName].type;
		const isFullstoryInitialized = isInitialized();

		if (!isFullstoryInitialized) return;

		const defaultProps: Partial<BaseEventProperties> = {
			cart_course_count: cart?.cartItems?.length,
			cart_total_value: cartTotal.raw,
		};

		const finalProps = {
			...defaultProps,
			...properties,
			user_id,
		};

		FullStory('trackEvent', {
			name: eventName,
			properties: { ...finalProps, event_type: eventType },
		});

		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.log(`=========TRACKING ${eventName}/${eventType}========`, {
				name: eventName,
				properties: { ...finalProps, event_type: eventType },
			});
		}
	};

	return (
		<TrackEventContext.Provider value={{ trackEvent }}>{children}</TrackEventContext.Provider>
	);
};

export const useTrackEvent = () => {
	const context = useContext(TrackEventContext);
	if (!context) {
		throw new Error('useTrackEvent must be used within a TrackEventProvider');
	}
	return context.trackEvent;
};

export const getDiscountReduction = (
	selectedCourseVersion: Courseversion | null,
): number | null => {
	if (!selectedCourseVersion) return null;

	return selectedCourseVersion.discountStatus === 'enabled' &&
		selectedCourseVersion.discountedPrice
		? selectedCourseVersion.price - selectedCourseVersion.discountedPrice
		: 0;
};
