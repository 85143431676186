import { Courseversion, DiscountCode, OrderCartItems } from '../payload-types';
import { calculatePrice } from './price';

type GenerateCartOrderTotalpricesParams = {
	items?: OrderCartItems;
};
type TotalsType = {
	totalVat: number;
	totalPriceWithoutVAT: number;
	total: number;
	fullTotalVat: number;
	fullTotalPriceWithoutVAT: number;
	fullTotal: number;
	hasVAT: boolean;
};

export function generateCartOrderTotalPrices({
	items,
}: GenerateCartOrderTotalpricesParams): TotalsType {
	const defaultTotals = {
		totalVat: 0,
		totalPriceWithoutVAT: 0,
		total: 0,
		fullTotalVat: 0,
		fullTotalPriceWithoutVAT: 0,
		fullTotal: 0,
		hasVAT: false,
	};
	const totalPrices = items?.reduce((acc, item) => {
		if (!item.price || !item.quantity || !item.priceWithoutVAT) {
			return acc;
		}

		const quantity = item.quantity || 0;

		const fullItemVAT = item.VAT || 0;
		const fullItemPriceWithoutVAT = item.priceWithoutVAT;
		const fullItemPrice = item.price;

		const itemVAT = item?.discountedVAT || fullItemVAT || 0;
		const itemPriceWithoutVAT = item?.discountedPriceWithoutVAT || fullItemPriceWithoutVAT;
		const itemPrice = item.discountedPrice || fullItemPrice;

		let totalItemVAT = calculatePrice(itemVAT * quantity);
		let totalItemPriceWithoutVAT = calculatePrice(itemPriceWithoutVAT * quantity);
		let totalItemPrice = calculatePrice(itemPrice * quantity);

		// business logic: apply discount code to only one item if there is more than 1
		const shouldUseDiscountCodeOnlyOnOneItem = item.discountCodeApplied && quantity > 1;
		if (shouldUseDiscountCodeOnlyOnOneItem) {
			totalItemVAT =
				calculatePrice(fullItemVAT * quantity) - calculatePrice(fullItemVAT - itemVAT);
			totalItemPriceWithoutVAT =
				calculatePrice(fullItemPriceWithoutVAT * quantity) -
				calculatePrice(fullItemPriceWithoutVAT - itemPriceWithoutVAT);
			totalItemPrice =
				calculatePrice(fullItemPrice * quantity) -
				calculatePrice(fullItemPrice - itemPrice);
		}

		const totalVat = calculatePrice(acc.totalVat + totalItemVAT);
		const totalPriceWithoutVAT = calculatePrice(
			acc.totalPriceWithoutVAT + totalItemPriceWithoutVAT,
		);
		const total = calculatePrice(acc.total + totalItemPrice);

		const fullTotalVat = calculatePrice(acc.fullTotalVat + fullItemVAT * quantity);
		const fullTotalPriceWithoutVAT = calculatePrice(
			acc.fullTotalPriceWithoutVAT + fullItemPriceWithoutVAT * quantity,
		);
		const fullTotal = calculatePrice(acc.fullTotal + fullItemPrice * quantity);

		const hasVAT = acc.hasVAT || Boolean(itemVAT && itemVAT > 0);

		return {
			totalVat,
			totalPriceWithoutVAT,
			total,
			// full prices dont consider any discounts
			fullTotalVat,
			fullTotalPriceWithoutVAT,
			fullTotal,
			hasVAT,
		};
	}, defaultTotals);

	return totalPrices || defaultTotals;
}
