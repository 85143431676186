'use client';

import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import { useDebounceCallback, useDebounceValue } from 'usehooks-ts';

import { z } from 'zod';

import type {
	OrderCartItems,
	Order,
	User,
	DiscountCode,
	Courseversion,
} from '../../../payload/payload-types';
import type { CartItem, PaymentDetails, StateType } from './reducer';

import { useAuth } from '../Auth';
import { cartReducer } from './reducer';
import { useLocale, useTranslations } from 'next-intl';
import { formatPrice } from '../../_utilities/format-price';
import { isTruthy } from '../../_utilities/is-truthy';
import { extractId } from '../../../shared/extract-id';
import { generateCartOrderTotalPrices } from '../../../payload/utilities/generate-cart-order-total-price';
import { toast } from '@shadcn/components/ui/use-toast';
import { Link } from '../../../navigation';
import { Button } from '../../_components/Button';
import { useTrackEvent } from '../TrackEventProvider';

export type CartContext = {
	addItemToCart: (item: CartItem) => void;
	setPaymentDetails: (paymentDetails: Order['paymentDetails']) => void;
	cart: StateType;
	cartIsEmpty: boolean | undefined;
	cartTotal: {
		formatted: string;
		raw: number;
	};
	cartTotalVat: {
		formatted: string;
		raw: number;
	};
	cartHasVat: boolean;
	clearCart: () => void;
	deleteItemFromCart: (cartItem: CartItem) => void;
	hasInitializedCart: boolean;
	isItemInCart: (cartItem: CartItem) => boolean;
	setActiveOrder: (order?: Order, user?: User | null) => void;
	setDiscountCodeText: (discountCodeText?: string) => void;
	isCartSyncInProgress: boolean;
	discountCodeError?: string | null;
	isDiscountCodeLoading: boolean;
	clearDiscountCode: () => void;
};

const Context = createContext({} as CartContext);

export const cartItemSchema = z.object({
	orderedCollection: z.object({
		relationTo: z.union([z.literal('courseversions'), z.literal('products')]),
		value: z.string(),
	}),
	selfParticipant: z.boolean(),
	quantity: z.number().min(1),
});
export const useCart = () => useContext(Context);

const arrayHasItems = array => Array.isArray(array) && array.length > 0;

const flattenCart = (cart: User['cart']) => {
	const flattenedCart = {
		discountCode: cart?.discountCode ? extractId(cart.discountCode) : null,
		discountCodeText: cart?.discountCodeText,
		cartItems: cart?.cartItems
			?.map(cartItem => {
				if (
					!cartItem?.orderedCollection ||
					typeof cartItem?.orderedCollection !== 'object'
				) {
					return null;
				}

				if ((cartItem?.quantity && cartItem?.quantity < 1) || !cartItem.quantity) {
					return null;
				}

				return {
					...cartItem,
					orderedCollection: {
						relationTo: cartItem.orderedCollection.relationTo,
						value: extractId(cartItem.orderedCollection.value),
					},
				};
			})
			.filter(Boolean) as CartItem[],
	};

	return flattenedCart;
};
// Step 1: Check local storage for a cart
// Step 2: If there is a cart, fetch the products and hydrate the cart
// Step 3: Authenticate the user
// Step 4: If the user is authenticated, merge the user's cart with the local cart
// Step 4B: Sync the cart to Payload and clear local storage
// Step 5: If the user is logged out, sync the cart to local storage only

export const CartProvider = props => {
	// const { setTimedNotification } = useNotifications();
	const { children } = props;
	const { status: authStatus, user } = useAuth();
	const locale = useLocale();
	const t = useTranslations('Cart');

	const [cart, dispatchCart] = useReducer(cartReducer, {
		cartItems: [],
		paymentDetails: [],
		activeOrder: undefined,
		hasInitialSync: false,
		discountCode: undefined,
	});

	const [total, setTotal] = useState<{
		formatted: string;
		raw: number;
	}>({
		formatted: formatPrice(0, locale, 'EUR'),
		raw: 0,
	});

	const [totalVat, setTotalVat] = useState<{
		formatted: string;
		raw: number;
	}>({
		formatted: formatPrice(0, locale, 'EUR'),
		raw: 0,
	});

	const [hasVat, setHasVat] = useState<boolean>(false);

	const hasInitializedLocalStorage = useRef(false);

	const [hasInitializedCart, setHasInitializedCart] = useState(false);
	const [hasInitializedUserCart, setHasInitializedUserCart] = useState(false);
	const [isCartSyncInProgress, setIsCartSyncInProgress] = useState<boolean>(false);
	const [discountCodeError, setDiscountCodeError] = useState<string | null>();
	const [isDiscountCodeLoading, setIsDiscountCodeLoading] = useState<boolean>(false);

	const validateLocalStorageCart = (cartItems?: OrderCartItems) => {
		if (cartItems && Array.isArray(cartItems) && cartItems.length) {
			return cartItems.filter(cartItem => {
				try {
					cartItemSchema.parse(cartItem);

					return true;
				} catch (_) {
					console.warn('validate localstorage cart ERR: ', _);
					return false;
				}
			});
		}
	};

	// Check local storage for a cart
	// If there is a cart, fetch the cartitems and hydrate the cart

	useEffect(() => {
		if (!hasInitializedLocalStorage.current) {
			const syncCartFromLocalStorage = async () => {
				const localCart = localStorage.getItem('cart');

				const parsedCart: { cartItems?: OrderCartItems } = JSON.parse(localCart || '{}');
				const validatedCart = validateLocalStorageCart(parsedCart?.cartItems);

				if (validatedCart && validatedCart?.length > 0) {
					setIsCartSyncInProgress(true);
					const initialCart = (
						await Promise.all(
							validatedCart.map(async args => {
								if (!args.orderedCollection) {
									throw new Error('Internal error');
								}

								const { quantity, orderedCollection } = args;
								const id = extractId(orderedCollection.value);

								if (!quantity || quantity < 1) {
									return null;
								}

								const url = `${process.env.NEXT_PUBLIC_SERVER_URL}/api/${orderedCollection.relationTo}/${id}?locale=${locale}`;
								const res = await fetch(url);
								const data = await res.json();

								if (data?.errors) {
									console.error(
										`Cart contains bad items. Url: ${url} error: ${data.errors}`,
									);

									return null;
								}

								if (!data.isAvailableForPurchase) {
									return null;
								}

								const cartItem = {
									...args,
									orderedCollection: {
										...orderedCollection,
										value: data,
									},
								};

								return cartItem;
							}),
						)
					).filter(isTruthy);

					dispatchCart({
						payload: {
							cartItems: initialCart,
						},
						type: 'SET_CART',
					});
					setIsCartSyncInProgress(false);
				}

				hasInitializedLocalStorage.current = true;
			};

			void syncCartFromLocalStorage();
		}
	}, []);

	// authenticate the user and if logged in, merge the user's cart with local state
	// only do this after we have initialized the cart to ensure we don't lose any items
	useEffect(() => {
		if (!hasInitializedLocalStorage.current) return;

		if (authStatus === 'loggedIn' && user?.cart) {
			// merge the user's cart with the local state upon logging in

			dispatchCart({
				payload: {
					...user.cart,
					discountCode: user?.cart?.discountCode ? user.cart.discountCode : null,
				},
				type: 'MERGE_CART',
			});

			const getOrder = async (orderId: string) => {
				return await fetch(
					`${process.env.NEXT_PUBLIC_SERVER_URL}/api/orders/${orderId}?locale=${locale}&depth=3`,
					{
						headers: {
							'Content-Type': 'application/json',
						},
						cache: 'no-cache',
					},
				).then(res => res.json());
			};

			if (typeof user?.activeOrder === 'string') {
				getOrder(user.activeOrder).then(order => {
					dispatchCart({
						type: 'SET_ACTIVE_ORDER',
						payload: order,
					});
				});
			} else {
				dispatchCart({
					type: 'SET_ACTIVE_ORDER',
					payload: user?.activeOrder ?? undefined,
				});
			}

			dispatchCart({
				type: 'SET_INITIAL_SYNC',
				payload: true,
			});
		}

		if (authStatus === 'loggedOut') {
			// clear the cart from local state after logging out
			dispatchCart({
				type: 'CLEAR_CART',
			});
		}

		// just a visitor, no localstorage cart, no user
		if (authStatus === undefined && !cart.hasInitialSync) {
			dispatchCart({
				type: 'SET_INITIAL_SYNC',
				payload: true,
			});
		}
	}, [user?.id, authStatus]);

	// every time the cart changes, determine whether to save to local storage or Payload based on authentication status
	// upon logging in, merge and sync the existing local cart to Payload
	const syncDependencyCartItems = cart.cartItems?.map(cartItem => {
		return {
			orderedCollectionId: extractId(cartItem.orderedCollection.value),
			quantity: cartItem.quantity,
			selfParticipant: cartItem.selfParticipant,
		};
	});
	const syncCartToPayload = async ({ _user, flattenedCart }) => {
		setIsCartSyncInProgress(true);
		const req = await fetch(
			`${process.env.NEXT_PUBLIC_SERVER_URL}/api/users/${_user.id}?locale=${locale}&depth=3`,
			{
				// Make sure to include cookies with fetch
				body: JSON.stringify({
					cart: flattenedCart,
				}),
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
				},
				method: 'PATCH',
			},
		);

		if (req.ok) {
			localStorage.setItem('cart', '[]');
		}

		const res = await req.json();

		if (res.errors) {
			console.warn('Error while syncing cart to payload: ', res.errors);

			const errorMessageString = res.errors?.map(err => err?.data?.[0]?.message).join('.');
			const trackEvent = useTrackEvent();

			if (errorMessageString) {
				toast({
					title: errorMessageString,
					variant: 'destructive',
					action: (
						<Button className="p-0 text-white" variant="ghost" size="small">
							<Link
								onClick={() =>
									trackEvent('go_to_cart_clicked', { component: 'toast' })
								}
								href={'/cart'}
							>
								{t('go-to-cart')}
							</Link>
						</Button>
					),
				});

				setIsCartSyncInProgress(false);
				return;
			}
		}
		const cart: User['cart'] = res?.doc?.cart;

		// dispatch here to update cart after cart hooks have run (eg discount was disabled etc)
		// will cause small price flicker if prices changed but it's edge case and fast in production
		if (cart?.cartItems?.length) {
			dispatchCart({
				type: 'MERGE_CART',
				payload: {
					...(cart || {}),
					discountCode: cart?.discountCode,
				},
			});
		} else {
			dispatchCart({
				type: 'CLEAR_CART',
			});
		}

		setIsCartSyncInProgress(false);
	};
	useEffect(() => {
		// wait until we have attempted authentication
		if (!hasInitializedLocalStorage.current || authStatus === 'isFetchingUser') return;
		if (!cart.hasInitialSync) return;
		// ensure that cart items are fully populated, filter out any items that are not
		// this will prevent discontinued products from appearing in the cart
		const flattenedCart = flattenCart(cart);
		if (user) {
			try {
				// TODO disable inputs if sync is in progress to prevent DB conflict errors
				if (!isCartSyncInProgress) {
					void syncCartToPayload({ _user: user, flattenedCart });
				}
			} catch (e) {
				console.error('Error while syncing cart to Payload.'); // eslint-disable-line no-console
			}

			if (cart.activeOrder && !cart.cartItems?.length) {
				dispatchCart({
					type: 'SET_ACTIVE_ORDER',
					payload: null,
				});
			}
		} else {
			localStorage.setItem('cart', JSON.stringify(flattenedCart));
		}

		setHasInitializedUserCart(true);
	}, [JSON.stringify(syncDependencyCartItems), user?.id, cart.hasInitialSync]);

	useEffect(() => {
		if (
			!hasInitializedUserCart ||
			isCartSyncInProgress ||
			isDiscountCodeLoading ||
			!cart.discountCodeText
		) {
			return;
		}

		setIsCartSyncInProgress(true);

		const postDiscountCode = async () => {
			setIsDiscountCodeLoading(true);
			setDiscountCodeError(null);
			try {
				const discountCodeResponse = await fetch(
					`${process.env.NEXT_PUBLIC_SERVER_URL}/api/discount-code?locale=${locale}`,
					{
						headers: {
							'Content-Type': 'application/json',
						},
						credentials: 'include',
						cache: 'no-store',
						method: 'POST',
						body: JSON.stringify({
							code: cart.discountCodeText,
						}),
					},
				)
					?.then(async res => {
						if (!res.ok) {
							setDiscountCodeError(
								t('there-was-an-unexpected-error-please-try-again'),
							);
							return null;
						}
						const json = await res.json();

						if ('error' in json && json.error) {
							dispatchCart({
								type: 'MERGE_CART',
								payload: discountCodeResponse.cart,
							});
							setDiscountCodeError(json.error);
							return null;
						}

						return json;
					})
					?.then(json => json);

				if (discountCodeResponse?.exists === false) {
					if (discountCodeResponse?.status === 'disabled') {
						setDiscountCodeError(t('discount-code-not-available'));
					} else {
						setDiscountCodeError(t('discount-code-does-not-exist'));
					}

					dispatchCart({
						type: 'MERGE_CART',
						payload: discountCodeResponse.cart,
					});
				} else if (
					discountCodeResponse?.exists &&
					discountCodeResponse?.status === 'enabled'
				) {
					// success!
					dispatchCart({
						type: 'MERGE_CART',
						payload: discountCodeResponse.cart,
					});
				}
			} catch (err) {
				if (err?.message && typeof err?.message === 'string') {
					console.error('Discount code error: ', err);
					setDiscountCodeError(err.message);
				} else {
					setDiscountCodeError(t('there-was-an-unexpected-error-please-try-again'));
				}
			}

			setIsCartSyncInProgress(false);
			setIsDiscountCodeLoading(false);
		};

		void postDiscountCode();
	}, [cart.discountCodeText]);

	const isItemInCart = useCallback(
		(incomingItem: CartItem): boolean => {
			let isInCart = false;
			const { cartItems: itemsInCart } = cart || {};
			if (Array.isArray(itemsInCart) && itemsInCart.length > 0) {
				isInCart = Boolean(
					itemsInCart.find(({ orderedCollection }) => {
						if (!orderedCollection) {
							return false;
						}
						if (!incomingItem?.orderedCollection) {
							return false;
						}
						return (
							(typeof orderedCollection.value === 'string'
								? orderedCollection.value === incomingItem.id
								: orderedCollection?.value.id === incomingItem.id) &&
							orderedCollection.relationTo ===
								incomingItem.orderedCollection.relationTo
						);
					}),
				);
			}
			return isInCart;
		},
		[JSON.stringify(cart)],
	);

	useEffect(() => {
		if (hasInitializedLocalStorage.current && hasInitializedUserCart && !hasInitializedCart) {
			setHasInitializedCart(true);
		}
	}, [hasInitializedLocalStorage.current, hasInitializedUserCart]);

	// this method can be used to add new items AND update existing ones
	const addItemToCart = useDebounceCallback((incomingItem: CartItem) => {
		dispatchCart({
			payload: incomingItem,
			type: 'ADD_ITEM',
		});
	}, 200);

	const deleteItemFromCart = useDebounceCallback((incomingItem: CartItem) => {
		dispatchCart({
			payload: incomingItem,
			type: 'DELETE_ITEM',
		});
	}, 200);

	const clearDiscountCode = useCallback(() => {
		dispatchCart({
			type: 'CLEAR_DISCOUNT_CODE',
		});
		const flattenedCart = flattenCart({
			...cart,
			discountCode: null,
			discountCodeText: '',
		});
		syncCartToPayload({ _user: user, flattenedCart });
		setDiscountCodeError(null);
		setIsDiscountCodeLoading(false);
		setDiscountCodeText('');
	}, [JSON.stringify(cart)]);

	const clearCart = useCallback(() => {
		dispatchCart({
			type: 'CLEAR_CART',
		});
	}, []);

	const setPaymentDetails = useCallback((paymentDetails: PaymentDetails) => {
		dispatchCart({
			payload: paymentDetails,
			type: 'SET_PAYMENT_DETAILS',
		});
	}, []);

	const setActiveOrder = useCallback<CartContext['setActiveOrder']>((order, user) => {
		dispatchCart({
			payload: order,
			type: 'SET_ACTIVE_ORDER',
		});
	}, []);

	const setDiscountCodeText = useCallback<CartContext['setDiscountCodeText']>(
		discountCodeText => {
			dispatchCart({
				payload: discountCodeText,
				type: 'SET_DISCOUNT_CODE_TEXT',
			});
		},
		[],
	);

	// calculate the new cart total whenever the cart changes
	useEffect(() => {
		if (!hasInitializedLocalStorage) return;

		// const calculatedPrices = cart?.cartItems?.reduce(
		// 	(acc, cartItem) => {
		// 		const collection = cartItem.orderedCollection.value;

		// 		if (typeof collection === 'string') {
		// 			console.error(
		// 				`CartItem orderedCollection wrong depth or orderedCollection not available, id: ${collection}`,
		// 			);

		// 			return acc;
		// 		}
		// 		const newHasVat = acc.newHasVat || Boolean(collection.VAT && collection.VAT > 0);

		// 		// TODO move this to utilities and use in FE & BE
		// 		// TODO apply discount code only once (if quantity is bigger than 1)
		// 		const itemVAT = (cartItem?.discountedVAT || cartItem?.VAT || 0) * cartItem.quantity;
		// 		const cartItemPriceWithoutVAT =
		// 			(cartItem?.discountedPriceWithoutVAT || cartItem.priceWithoutVAT!) *
		// 			cartItem.quantity;
		// 		const cartItemPrice =
		// 			(cartItem.discountedPrice || cartItem.price!) * cartItem.quantity;

		// 		return {
		// 			newHasVat,
		// 			newTotal:
		// 				acc.newTotal +
		// 				(cartItem.discountedPrice || cartItem.price!) * (cartItem?.quantity || 0),
		// 			newTotalVat:
		// 				acc.newTotalVat +
		// 				(cartItem.discountedVAT || cartItem.VAT || 0) * (cartItem.quantity || 0),
		// 		};
		// 	},
		// 	{
		// 		newTotal: 0,
		// 		newTotalVat: 0,
		// 		newHasVat: false,
		// 	},
		// );

		const calculatedPrices = generateCartOrderTotalPrices({
			items: cart.cartItems,
		});

		if (calculatedPrices) {
			const { total, totalVat, hasVAT } = calculatedPrices;
			setTotal({
				formatted: formatPrice(total, locale, 'EUR'),
				raw: total,
			});

			setTotalVat({
				formatted: formatPrice(totalVat, locale, 'EUR'),
				raw: totalVat,
			});

			setHasVat(hasVAT);
		} else {
			console.error(`Could not calculate cart prices, cartItems: ${cart.cartItems}`);
		}
	}, [JSON.stringify(syncDependencyCartItems), hasInitializedLocalStorage, cart.discountCode]);

	return (
		<Context.Provider
			value={{
				addItemToCart,
				cart,
				cartIsEmpty: hasInitializedCart && !arrayHasItems(cart?.cartItems),
				cartTotal: total,
				cartTotalVat: totalVat,
				cartHasVat: hasVat,
				clearCart,
				deleteItemFromCart,
				hasInitializedCart,
				isItemInCart,
				setPaymentDetails,
				setActiveOrder,
				setDiscountCodeText,
				isCartSyncInProgress,
				discountCodeError,
				isDiscountCodeLoading,
				clearDiscountCode,
			}}
		>
			{children && children}
		</Context.Provider>
	);
};
