import _ from 'lodash';
import { VAT_MULTIPLIER } from './config';

export const roundPrice = (price: number): number => {
	return _.round(price, 2);
};

export const calculaceVat = (price: number): number => {
	return roundPrice(price * VAT_MULTIPLIER - price);
};

export const calculatePrice = (price: number, addVat?: boolean): number => {
	if (addVat) {
		return roundPrice(price * VAT_MULTIPLIER);
	}

	return roundPrice(price);
};
