export const formatPrice = (price: number, locale: string, currency?: string): string => {
	return price.toLocaleString(locale, {
		currency: currency || 'EUR',
		style: 'currency',
		useGrouping: true,
		minimumFractionDigits: 2,
		//@ts-expect-error target: es2020+
		trailingZeroDisplay: 'stripIfInteger',
	});
};
